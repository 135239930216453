import { computed, ref, Ref } from 'vue'

export default function useNavigation ({
  container,
  itemPerPage = 1,
  // visibleItems = 2,
  countItems
}: {
  container: Ref<HTMLDivElement | undefined>,
  itemPerPage?: number,
  visibleItems?: number
  countItems?: Ref<number>
}) {
  const currentIndex = ref(0)

  function getChildrenLength (): number {
    if (countItems && countItems.value) {
      return countItems.value
    }
    if (container.value?.dataset.itemsLength) {
      return Number(container.value?.dataset.itemsLength)
    }
    return container.value?.children.length || 0
  }

  const isDisabledPrev = computed<boolean>(() => !container.value || currentIndex.value === 0)
  const isDisabledNext = computed<boolean>(() => {
    if (!container.value) {
      return true
    }

    return currentIndex.value >= getChildrenLength() - itemPerPage
  })

  function getChild (index: number): HTMLDivElement | undefined {
    return container.value?.children[index] as HTMLDivElement
  }

  function scrollContainer (offsetLeft) {
    container.value?.scroll({
      left: offsetLeft,
      behavior: 'smooth'
    })
  }

  function prev () {
    if (isDisabledPrev.value) {
      return
    }
    currentIndex.value = currentIndex.value - itemPerPage
    if (currentIndex.value < 0) {
      currentIndex.value = 0
    }

    const offsetLeft = getChild(currentIndex.value)?.offsetLeft || 0
    scrollContainer(offsetLeft)
  }

  function next () {
    if (isDisabledNext.value) {
      return
    }

    currentIndex.value = currentIndex.value + itemPerPage
    if (currentIndex.value > getChildrenLength() - 1) {
      currentIndex.value = getChildrenLength() - 1
    }

    const offsetLeft = getChild(currentIndex.value)?.offsetLeft || 0
    scrollContainer(offsetLeft)
  }

  const scroll = false
  function onScrollWrapper (event) {
    if (scroll) {
      event.preventDefault()
      if (event.deltaY > 0) {
        next()
      } else if (event.deltaY < 0) {
        prev()
      }
    }
  }

  return {
    currentIndex,
    isDisabledPrev,
    isDisabledNext,
    scrollContainer,
    prev,
    next,
    onScrollWrapper
  }
}
