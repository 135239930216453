import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42836f32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home_row -desktop-column -banners-column" }
const _hoisted_3 = { class: "home_row" }
const _hoisted_4 = { class: "home_row -desktop-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_limited_offer = _resolveComponent("home-limited-offer")!
  const _component_home_club = _resolveComponent("home-club")!
  const _component_home_events = _resolveComponent("home-events")!
  const _component_home_courses = _resolveComponent("home-courses")!
  const _component_home_new_level = _resolveComponent("home-new-level")!
  const _component_home_catalog = _resolveComponent("home-catalog")!
  const _component_as_preloader = _resolveComponent("as-preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_home_limited_offer)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_home_club, {
              onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlerLoaded('club', $event)))
            }),
            _createVNode(_component_home_events, {
              onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlerLoaded('events', $event)))
            }),
            _createVNode(_component_home_courses, {
              onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handlerLoaded('courses', $event)))
            }),
            _createVNode(_component_home_new_level, {
              onLoaded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handlerLoaded('new-level', $event)))
            }),
            _createVNode(_component_home_catalog, {
              onLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handlerLoaded('catalog', $event)))
            })
          ])
        ], 64))
      : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
  ]))
}