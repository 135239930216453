import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f1d35ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "club-article_preview preview" }
const _hoisted_2 = { class: "preview_tags" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "preview_date" }
const _hoisted_5 = { class: "club-article_description description" }
const _hoisted_6 = { class: "description_wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "description_text"
}
const _hoisted_8 = { class: "description_more" }
const _hoisted_9 = { class: "club-article_actions actions" }
const _hoisted_10 = { class: "actions_wrap" }
const _hoisted_11 = {
  key: 0,
  class: "actions_action action views"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_heart = _resolveComponent("icon-heart")!
  const _component_icon_eye = _resolveComponent("icon-eye")!

  return (_openBlock(), _createElementBlock("div", {
    class: "club-article",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openArticle && _ctx.openArticle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.article.labels, (label, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "preview_tag"
          }, _toDisplayString(label), 1))
        }), 128))
      ]),
      _createElementVNode("img", {
        class: "preview_img",
        src: _ctx.previewImage,
        alt: _ctx.article.title,
        loading: "lazy"
      }, null, 8, _hoisted_3),
      _createElementVNode("time", _hoisted_4, _toDisplayString(_ctx.date), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.article.shortDescription)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.article.shortDescription), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('more')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (_ctx.article.hasOwnProperty('liked'))
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["actions_action like -enabled", { '-active': _ctx.article.liked }])
            }, [
              _createVNode(_component_icon_heart, { class: "actions_icon" }),
              _createTextVNode(" " + _toDisplayString(_ctx.article.likesCount), 1)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      (_ctx.article.hasOwnProperty('countViews'))
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
            _createVNode(_component_icon_eye, { class: "actions_icon" }),
            _createTextVNode(" " + _toDisplayString(_ctx.article.countViews), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}