import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_banner = _resolveComponent("home-banner")!
  const _component_home_block = _resolveComponent("home-block")!

  return (_ctx.bannersCatalog.length)
    ? (_openBlock(), _createBlock(_component_home_block, {
        key: 0,
        class: "g-home-block home-limited-offer",
        "link-all": { name: 'banners' }
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('limitedOffer')), 1)
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", {
            class: "g-home-block_wrapper",
            ref: "refContainer",
            onWheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigation.onScrollWrapper && _ctx.navigation.onScrollWrapper(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersCatalog, (banner) => {
              return (_openBlock(), _createBlock(_component_home_banner, {
                key: `banner-catalog-${banner.id}`,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.catalog,
                platform: _ctx.bannersPlatforms.mobile,
                banner: banner,
                class: "home-limited-offer_banner g-home-block_item",
                where: "block"
              }, null, 8, ["position", "placement", "platform", "banner"]))
            }), 128))
          ], 544)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}