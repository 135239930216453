
import { defineComponent, reactive, ref, watch } from 'vue'

import AcButton from '@ui-kit/components/AcButton.vue'
import HomeBlock from '../Block.vue'
import ClubArticle from './Article.vue'
import ModalChooseClub from './ModalChooseClub.vue'

import useNavigation from '@web/components.v2/Home/common/useNavigation'
import useClub from '@web/composition/useClub'
import Sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'

export default defineComponent({
  name: 'HomeClub',
  components: {
    ModalChooseClub,
    ClubArticle,
    HomeBlock,
    AcButton
  },
  emits: ['loaded'],
  setup (props, ctx) {
    let reInit = false
    const loaded = ref(false)
    const refContainer = ref<HTMLDivElement>()

    const {
      showModalChooseClub,
      communities,
      articles,
      fetchArticles,
      isShowArticles,
      isShowClubBlock,
      previewLoaded,
      isHaveClubSubscribe,
      showInfo,
      showAll,
      showFaq,
      showAboutClub,
      showChat,
      handlerClubLookAll,
      handlerClubFaq,
      handlerClubChat,
      handlerChooseClub,
      handlerAboutClub,
      countItems,
      communitiesModalChooseClub
    } = useClub()

    async function init () {
      try {
        loaded.value = false
        ctx.emit('loaded', false)
        await fetchArticles()
      } catch (e) {
        Sentry.captureException(e)
        Logger.error(e)
        if (!reInit) {
          reInit = true
          setTimeout(() => {
            init()
          }, 1000)
        }
      } finally {
        loaded.value = true
        ctx.emit('loaded', true)
      }
    }

    watch(communities, (newCommunities, oldCommunities) => {
      if (newCommunities.length && newCommunities.length !== (oldCommunities?.length || 0)) {
        init()
      }
    }, {
      immediate: true
    })

    return {
      loaded,
      refContainer,
      showModalChooseClub,
      communitiesModalChooseClub,
      communities,
      articles,
      isShowArticles,
      isShowClubBlock,
      previewLoaded,
      isHaveClubSubscribe,
      showInfo,
      showAll,
      showFaq,
      showAboutClub,
      showChat,
      navigation: reactive(useNavigation({
        container: refContainer,
        itemPerPage: 2,
        visibleItems: 2,
        countItems
      })),
      handlerClubLookAll,
      handlerClubFaq,
      handlerClubChat,
      handlerChooseClub,
      handlerAboutClub
    }
  }
})
