
import { computed, defineComponent } from 'vue'
import { useMq } from 'vue3-mq'

import AsPreloader from '@web/components/AsPreloader.vue'
import i18n from '@web/plugins/i18n'

export default defineComponent({
  name: 'HomeBlock',
  components: {
    AsPreloader
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showInfoBtn: {
      type: Boolean,
      default: false
    },
    showAllBtn: {
      type: Boolean,
      default: true
    },
    isLoaded: {
      type: Boolean,
      default: true
    },
    linkAll: {
      type: [String, Object],
      default: ''
    },
    isDisabledPrev: {
      type: Boolean,
      default: true
    },
    isDisabledNext: {
      type: Boolean,
      default: true
    }
  },
  emit: ['click:info', 'nav:all', 'nav:prev', 'nav:next'],
  setup (props, ctx) {
    const mq = useMq()

    const viewAllText = computed(() => mq.mobile ? i18n.global.t('All') : i18n.global.t('View all'))

    function handlerNav (type: 'all' | 'prev' | 'next') {
      if ((type === 'prev' && props.isDisabledPrev) || (type === 'next' && props.isDisabledNext)) {
        return
      }
      ctx.emit(`nav:${type}`)
    }

    return {
      viewAllText,
      handlerNav
    }
  }
})
