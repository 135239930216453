
import { defineComponent, ref, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import Home from '@web/components.v2/Home/index.vue'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPushPrompt from '@web/components/AsPushPrompt.vue'
import AsBanner from '@web/components/AsBanner.vue'

import i18n from '@web/plugins/i18n'

import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'
import { useCoursesBanners } from '@web/composition/useBanners'

export default defineComponent({
  name: 'VHome',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsPushPrompt,
    AsBanner,

    Home
  },
  setup () {
    const store = useStore()

    const isOpenMenu = ref(false)

    const user = computed(() => store.getters['user/user'])
    const username = computed(() => store.getters['user/username'])
    const menu = computed(() => store.getters['menu/general'])
    const title = computed(() => i18n.global.t('myCourses'))

    useMeta({
      title: title.value
    })

    const {
      bannersSidebar,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    } = useCoursesBanners()

    function onSelectMenuItem (item) {
      if (item?.link?.name === 'courses') {
        isOpenMenu.value = false
      }
    }

    return {
      isOpenMenu,
      username,
      user,
      title,
      menu,
      onSelectMenuItem,
      // Banners sidebar for desktop
      bannersSidebar,
      bannersPlatforms,
      bannersPlacements,
      bannersPosition
    }
  }
})
