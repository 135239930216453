import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59b3d8b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "g-home-block_wrapper",
  ref: "refContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event = _resolveComponent("event")!
  const _component_events = _resolveComponent("events")!
  const _component_home_block = _resolveComponent("home-block")!

  return (_ctx.showBlock)
    ? (_openBlock(), _createBlock(_component_home_block, {
        key: 0,
        class: "g-home-block home-events",
        "show-info-btn": false,
        "link-all": { name: 'home-events' },
        "current-item-index": _ctx.navigation.currentIndex,
        "onNav:prev": _ctx.navigation.prev,
        "onNav:next": _ctx.navigation.next,
        "is-disabled-prev": _ctx.navigation.isDisabledPrev,
        "is-disabled-next": _ctx.navigation.isDisabledNext
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('dontMissIt')), 1)
        ]),
        body: _withCtx(() => [
          _createVNode(_component_events, null, {
            events: _withCtx(({ events }) => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events, (event) => {
                  return (_openBlock(), _createBlock(_component_event, {
                    key: event.id,
                    event: event,
                    class: "home-events_event g-home-block_item"
                  }, null, 8, ["event"]))
                }), 128))
              ], 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["current-item-index", "onNav:prev", "onNav:next", "is-disabled-prev", "is-disabled-next"]))
    : _createCommentVNode("", true)
}