
import { defineComponent, reactive, ref, onMounted } from 'vue'

import HomeBlock from '@web/components.v2/Home/Block.vue'
import HomeBanner from './Banner.vue'

import useNavigation from '@web/components.v2/Home/common/useNavigation'
import { useCoursesBanners } from '@web/composition/useBanners'

export default defineComponent({
  name: 'HomeLimitedOffer',
  components: {
    HomeBlock,
    HomeBanner
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const refContainer = ref<HTMLDivElement>()

    onMounted(() => ctx.emit('loaded', true))

    return {
      ...useCoursesBanners(),
      refContainer,
      navigation: reactive(useNavigation({ container: refContainer, itemPerPage: 1, visibleItems: 2 }))
    }
  }
})
