
import { defineComponent, reactive, ref, computed } from 'vue'

import HomeBlock from '@web/components.v2/Home/Block.vue'
import Item from './Item.vue'
import Items from './Items.vue'

import useCatalog from '@web/composition/useCatalog'
import useNavigation from '@web/components.v2/Home/common/useNavigation'

export default defineComponent({
  name: 'HomeCatalog',
  components: {
    HomeBlock,
    Items,
    Item
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const refContainer = ref<HTMLDivElement>()
    const loaded = ref(false)
    const {
      items
    } = useCatalog()

    const countItems = computed<number>(() => items.value?.length || 0)

    const notShowCatalog = computed(() => loaded.value && !countItems.value)
    const showCatalog = computed(() => loaded.value && countItems.value)

    function onLoaded (isLoaded) {
      loaded.value = isLoaded
      ctx.emit('loaded', isLoaded)
    }

    return {
      notShowCatalog,
      showCatalog,
      refContainer,
      items,
      onLoaded,
      navigation: reactive(useNavigation({
        container: refContainer,
        itemPerPage: 1,
        visibleItems: 2,
        countItems
      }))
    }
  }
})
