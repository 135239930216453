
import { defineComponent, onMounted, computed } from 'vue'

import IconHeart from '@web/components/icons/IconHeart.vue'
// import IconFavourite from '@web/components/icons/IconFavourite.vue'
import IconEye from '@web/components/icons/IconEye.vue'

import { Article } from '@web/types/Article'
import { useStore } from '@web/store'
import { dislikeArticle, favouriteArticle, likeArticle, unfavouriteArticle } from '@web/services/Analytics/events'
import Analytics from '@web/services/Analytics/Analytics'
import urlParse from 'url-parse'
import getBaseUrl from '@web/common/getBaseUrl'
import { getUrlWithAuthParams } from '@web/common/Utils'
import Time from '@web/common/Time'

export default defineComponent({
  name: 'ClubArticle',
  props: {
    article: {
      type: Object as () => Article,
      required: true
    }
  },
  components: {
    IconHeart,
    // IconFavourite,
    IconEye
  },
  setup (props) {
    const store = useStore()

    const eventParams = {
      source: 'platform_article_feed',
      title: props.article.title
    }

    const previewImage = computed(() => props.article.previewImage)
    const croppedShortDescription = computed(() => getBaseUrl(props.article.shortDescription))

    function toggleLike () {
      if (props.article.liked) {
        dislikeArticle(eventParams)
      } else {
        likeArticle(eventParams)
      }

      store.dispatch('article/toggleLike', {
        id: props.article.id,
        liked: !!props.article.liked
      })
    }

    function toggleFavourite () {
      if (props.article.isFavourite) {
        unfavouriteArticle(eventParams)
      } else {
        favouriteArticle(eventParams)
      }

      store.dispatch('article/toggleFavourite', {
        id: props.article.id,
        isFavourite: !!props.article.isFavourite
      })
    }

    function openArticle () {
      Analytics.send({
        action: 'articles_preview_click'
      })

      let path = props.article.url
      if (path.indexOf(getBaseUrl() || '') !== 0) {
        path = `${getBaseUrl() || ''}${props.article.url}`
      }
      const url = urlParse(path, true)
      url.set('query', {
        utm_source: 'lk',
        utm_medium: 'article_preview',
        utm_campaign: String(props.article.id),
        utm_term: props.article.title
      })
      window.location.href = getUrlWithAuthParams(url.toString())
    }

    onMounted(() => {
      Analytics.send({
        action: 'articles_preview'
      })
    })

    return {
      previewImage,
      croppedShortDescription,
      toggleLike,
      toggleFavourite,
      openArticle,
      date: Time(props.article.publishedAt).format('DD MMMM')
    }
  }
})
