
import { defineComponent, reactive, ref, computed } from 'vue'

import HomeBlock from '@web/components.v2/Home/Block.vue'
import Course from './Course.vue'
import Courses from './Courses.vue'
import AcButton from '@ui-kit/components/AcButton.vue'

import useNavigation from '@web/components.v2/Home/common/useNavigation'
import { useRouter } from 'vue-router'
import LocalStorage from '@web/common/LocalStorage'
import useStreams from '@web/composition/useStreams'
import useCourses from '@web/composition/useCourses'

export default defineComponent({
  name: 'HomeCourses',
  components: {
    HomeBlock,
    Course,
    Courses,
    AcButton
  },
  emits: ['loaded'],
  setup (props, ctx) {
    const router = useRouter()

    const {
      streams
    } = useStreams()

    const {
      courses
    } = useCourses()

    const countItems = computed(() => Object.values(courses.value).length || 0)

    const loaded = ref(false)
    const refContainer = ref<HTMLDivElement>()

    const CONTINUE_TRAINING_KEY = 'acProject_continue_training'
    const isShowContinueTraining = Boolean(LocalStorage.get(CONTINUE_TRAINING_KEY))
    function onHandlerContinueTraining () {
      let route = {
        name: 'home-courses'
      }
      if (LocalStorage.get(CONTINUE_TRAINING_KEY)) {
        const _route = JSON.parse(LocalStorage.get(CONTINUE_TRAINING_KEY) || '')
        if (_route.name !== 'home-courses') {
          const stream = streams.value.find(s => s.id === Number(_route.params.streamId || 0))
          if (stream) {
            route = _route
          }
        } else {
          route = _route
        }
      }
      if (route) {
        router.push(route)
      }
    }

    function onLoaded () {
      loaded.value = true
      ctx.emit('loaded', true)
    }

    return {
      loaded,
      refContainer,
      navigation: reactive(useNavigation({
        container: refContainer,
        itemPerPage: 1,
        visibleItems: 2,
        countItems
      })),
      isShowContinueTraining,
      onHandlerContinueTraining,
      onLoaded
    }
  }
})
