import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lCommon homePage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_push_prompt = _resolveComponent("as-push-prompt")!
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_home = _resolveComponent("home", true)!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_as_push_prompt),
    _createVNode(_component_mq_responsive, {
      class: "homePage_wrapper",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.username,
          avatar: _ctx.user.avatar,
          class: "homePage_header -courses",
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event)),
          "onItem:select": _ctx.onSelectMenuItem,
          "show-points": ""
        }, null, 8, ["title", "avatar", "onItem:select"]),
        _withDirectives(_createVNode(_component_home, { class: "homePage_body" }, null, 512), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb)
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, { class: "homePage_row" }, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          content: _withCtx(() => [
            _createVNode(_component_home)
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}