
import { computed, defineComponent, onMounted, ref, watch } from 'vue'

import HomeLimitedOffer from '@web/components.v2/Home/LimitedOffer/index.vue'
import HomeClub from '@web/components.v2/Home/Club/index.vue'
import HomeEvents from '@web/components.v2/Home/Events/index.vue'
import HomeCourses from '@web/components.v2/Home/Courses/index.vue'
import HomeCatalog from '@web/components.v2/Home/Catalog/index.vue'
import HomeNewLevel from '@web/components.v2/Home/NewLevel/index.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import Logger from '@web/common/Logger'
import { performanceEnd } from '@web/common/Utils'
import Analytics from '@web/services/Analytics/Analytics'
import FirebasePerformance from '@web/services/firebasePerformance'

export default defineComponent({
  name: 'Home',
  components: {
    HomeCourses,
    HomeLimitedOffer,
    HomeClub,
    HomeEvents,
    HomeCatalog,
    HomeNewLevel,
    AsPreloader
  },
  setup () {
    const loaded = ref(true)
    const blockIsLoaded = ref({
      club: false,
      events: false,
      courses: false,
      'new-level': false,
      catalog: false
    })

    const allBlockIsLoaded = computed(() => Object.values(blockIsLoaded.value).every(isLoaded => isLoaded))

    function handlerLoaded (block: 'club' | 'events' | 'courses' | 'new-level' | 'catalog', isLoaded: boolean) {
      blockIsLoaded.value[block] = isLoaded
    }

    let homePageLoading = performance.now()
    let startLoading = Date.now()

    onMounted(async () => {
      homePageLoading = performance.now()
      startLoading = Date.now()
    })

    watch(allBlockIsLoaded, (isLoaded) => {
      if (isLoaded) {
        homePageLoading = performanceEnd(homePageLoading)
        Logger.info(`Home page loaded: ${homePageLoading} ms`)
        Analytics.send({
          category: 'ux.performance',
          action: 'load_home',
          label: homePageLoading.toString()
        })
        FirebasePerformance.record({
          traceName: 'load_home',
          startTime: startLoading
        })
      }
    }, {
      immediate: true
    })

    return {
      loaded,
      handlerLoaded
    }
  }
})
