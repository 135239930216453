import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf054ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "g-home-block_wrapper",
  ref: "refContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!
  const _component_items = _resolveComponent("items")!
  const _component_home_block = _resolveComponent("home-block")!

  return _withDirectives((_openBlock(), _createBlock(_component_home_block, {
    class: "g-home-block home-catalog",
    "link-all": { name: 'home-catalog' },
    "current-item-index": _ctx.navigation.currentIndex,
    "onNav:prev": _ctx.navigation.prev,
    "onNav:next": _ctx.navigation.next,
    "is-disabled-prev": _ctx.navigation.isDisabledPrev,
    "is-disabled-next": _ctx.navigation.isDisabledNext
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('courseCatalog')), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_items, {
        preview: "",
        onLoaded: _ctx.onLoaded
      }, {
        items: _withCtx(({ items }) => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
              return (_openBlock(), _createBlock(_component_item, {
                key: item.id,
                item: item,
                class: "home-catalog_item g-home-block_item"
              }, null, 8, ["item"]))
            }), 128))
          ], 512)
        ]),
        _: 1
      }, 8, ["onLoaded"])
    ]),
    _: 1
  }, 8, ["current-item-index", "onNav:prev", "onNav:next", "is-disabled-prev", "is-disabled-next"])), [
    [_vShow, _ctx.showCatalog]
  ])
}