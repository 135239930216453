
import { computed, defineComponent, PropType } from 'vue'

import { Placement, Platform } from '@web/mapper/BannerMapper'
import useBanner from '@web/composition/useBanner'
import useGetImageWithSize from '@web/composition/useGetImageWithSize'

export default defineComponent({
  name: 'HomeBanner',
  props: {
    banner: {
      type: Object as PropType<Banner>,
      required: true
    },
    position: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: Placement.catalog
    },
    platform: {
      type: String,
      default: Platform.mobile
    },
    where: {
      type: String as PropType<'block' | 'page'>,
      default: 'block'
    }
  },
  setup (props) {
    const image = computed(() => useGetImageWithSize({ image: props.banner?.image, width: 320 * 1.5 }))
    return {
      ...useBanner({
        banner: props.banner,
        position: `${props.platform}_${props.position}_${props.placement}`
      }),
      image
    }
  }
})
