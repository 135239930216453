
import { defineComponent, onMounted, reactive, ref } from 'vue'

import HomeBlock from '@web/components.v2/Home/Block.vue'
import Event from './Event.vue'
import Events from './Events.vue'

import useNavigation from '@web/components.v2/Home/common/useNavigation'

export default defineComponent({
  name: 'HomeEvents',
  components: {
    HomeBlock,
    Events,
    Event
  },
  emits: ['loaded'],
  setup (props, ctx) {
    // TODO Пока не реализовано
    const showBlock = false
    const refContainer = ref<HTMLDivElement>()

    onMounted(() => ctx.emit('loaded', true))

    return {
      showBlock,
      refContainer,
      navigation: reactive(useNavigation({ container: refContainer, itemPerPage: 2, visibleItems: 3 }))
    }
  }
})
